import React from 'react'
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            <a href='https://www.linkedin.com/in/julien-sarda/' target='_blank' rel='noreferrer'>
            <BsLinkedin />
            </a>
        </div>
        <div>
            <a href='https://github.com/JUJUdev1' target='_blank' rel='noreferrer'>
            <FaGithub />
            </a>
        </div>
    </div>
  )
}

export default SocialMedia