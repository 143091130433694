import React from "react";

const NavigationDots = ({ active }) => {
  return (
    <div className="app__navigation">
      {[
        "accueil",
        "à propos",
        "projets",
        "skills",
        "testimonials",
        "contact",
      ].map((item, index) => (
        <a
          href={`#${item}`}
          key={item + index}
          className="app__navigation-dot"
          style={active === item ? { backgroundColor: "#673de6" } : {}}
        />
      ))}
    </div>
  );
};

export default NavigationDots;
