import circle from '../assets/circle.svg';
import profile from '../assets/profile.png';
import sass from '../assets/sass.png';
import react from '../assets/react.png';
import git from '../assets/git.png';
import email from '../assets/email.svg';
import phone from '../assets/phone.svg';
import tailwind from '../assets/tailwind.png';
import redux from '../assets/redux.svg';

export default {
   profile,circle,sass,react,git,email,phone,tailwind,redux
}